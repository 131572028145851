<template>
	<section>
		<div class="card">
			<div class="card-header">
				<div class="card-title h4">Mis Ordenes</div>
			</div>
			<div v-if="tabla.cargando" class="card-body">
				<div class="loading loading-lg"></div>
			</div>
			<table v-if=" ! tabla.cargando" class="table table-striped table-hover">
				<thead>
					<tr>
						<th class="text-center">Orden</th>
						<th>Estado</th>
						<th>Cliente</th>
						<th>Fecha</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(i, k) in tabla.listado" :key="k" @click="irOrden(i.id)" class="c-hand">
						<td class="text-center">{{ i.id }}</td>
						<td>{{ i.estado }}</td>
						<td>{{ i.cliente_nombre }}</td>
						<td>{{ i.creado }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'TN_Ordenes',
		data() {
			return {
				tabla: {
					cargando: false,
					listado: []
				}
			}
		},
		mounted() {
			document.title = 'Mis Ordenes';
			this.infoUsuario();
			this.cargarTabla();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => this.$store.commit('setUser', res.data))
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			cargarTabla() {
				this.tabla.cargando = true;
				this.$store.state.api
					.get('ordenes/tn_listado')
					.then(res => this.tabla.listado = res.data)
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.tabla.cargando = false);
			},
			irOrden(id) {
				this.$router.push({
					name: 'TN_Orden',
					params:{ id: id }
				});
			}
		}
	}
</script>
